import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IPage } from '@/types/api/common';
import {
  ILoginRequest,
  IQrAgentRequest,
  IQrAgentLoginRequest,
  ISMSCodeRequest,
  IEmailCodeRequest,
  IQrAgentResponse,
  IUserInfo,
  IVerifyRequest,
  IGrant,
  IGrantFolders,
  IGrantSet,
  IImportAdmins,
  NotificationUnreadCount,
} from '@/types/api/admin';

export default class Admin {
  /**
   * 管理员登录 返回 AccessToken
   * @param data
   * @returns
   */
  static loginAccount(data: ILoginRequest): Promise<string> {
    return Http.post('/m.api/admin/login', data);
  }

  /**
   * 企业微信Web登录Agent信息
   * @param data
   * @returns
   */
  static getQrAgent(data: IQrAgentRequest): Promise<IQrAgentResponse> {
    return Http.post('/m.api/admin/agent', data);
  }

  /**
   * 企业微信QR登录 返回AccessToken
   * @param data
   * @returns
   */
  static ewxQrLogin(data: IQrAgentLoginRequest): Promise<string> {
    return Http.post('/m.api/admin/ewxQrLogin', data);
  }

  /**
   * 当前登录管理员信息
   * @returns
   */
  static getUserInfo(): Promise<IUserInfo> {
    return Http.post('/m.api/admin/info');
  }

  /**
   * 当前登录管理员信息 配置不做任何处理
   * @returns
   */
  static getUserInfo1(): Promise<IUserInfo> {
    return Http.post('/m.api/admin/info', null, {
      // @ts-ignore 检测到未登录，不进行任何处理
      notAuthLogin: true,
      // @ts-ignore 检测到返回错误信息，不处理
      hideErrorMsg: true,
    });
  }

  /**
   * 发送短信验证码
   * @returns
   */
  static sendSms(data: ISMSCodeRequest): Promise<string> {
    return Http.post('/m.api/admin/sendVerifyCodeMobile', data);
  }

  /**
   * 发送邮箱验证码
   * @returns
   */
  static sendEmail(data: IEmailCodeRequest): Promise<string> {
    return Http.post('/m.api/admin/sendVerifyCodeEmail', data);
  }

  /**
   * 校验验证码 失败抛异常
   * @returns
   */
  static checkVerifyCode(data: IVerifyRequest): Promise<string> {
    return Http.post('/m.api/admin/checkVerifyCode', data);
  }

  /**
   * 找回密码 成功返回OK
   * @returns
   */
  static findPassword(data: ILoginRequest): Promise<string> {
    return Http.post('/m.api/admin/findPassword', data);
  }

  /**
   * 授权
   * @returns
   */
  @apiPermission(['sys:admin:grant'], '')
  static grant(data: IGrant): Promise<string> {
    return Http.post('/m.api/admin/grant', data);
  }

  /**
   * 授权数据
   * @returns
   */
  @apiPermission(['sys:admin:grantfolders'], '')
  static grantFolders(data: IGrantFolders): Promise<string> {
    return Http.post('/m.api/admin/grantFolders', data);
  }

  /**
   * 授权（权限集）
   * @returns
   */
  @apiPermission(['sys:admin:grantset'], '')
  static grantSet(data: IGrantSet): Promise<string> {
    return Http.post('/m.api/admin/grantSet', data);
  }

  /**
   * 导入用户
   * @returns
   */
  @apiPermission(['sys:admin:import'], {})
  static importAdmins(data: FormData): Promise<IImportAdmins> {
    return Http.postForm('/m.api/admin/importAdmins', data);
  }

  /**
   * 导入用户模板
   * @returns
   */
  static importAdminsTemplate(data: any): Promise<any> {
    return Http.post('/m.api/admin/importAdminsTemplate', data, { responseType: 'blob' });
  }

  /**
   * 查看通知详情
   * @returns
   */
  static notificationsDetails(data: any): Promise<any> {
    return Http.post('/m.api/admin/notificationsDetails', data);
  }

  /**
   * 分页查询通知
   * @returns
   */
  static notificationsPage(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin/notificationsPage', {
      notificationsQueryDTO: data,
    });
  }

  /**
   * 权限点
   * @returns
   */
  @apiPermission(['sys:admin:permissionlist'], {})
  static permissionList(data: any): Promise<any> {
    return Http.post('/m.api/admin/permissionList', data);
  }

  /**
   * 权限集
   * @returns
   */
  @apiPermission(['sys:admin:permissionset'], {})
  static permissionSet(data: any): Promise<any> {
    return Http.post('/m.api/admin/permissionSet', data);
  }

  /**
   * 更新通知为已读
   * @returns
   */
  static updateNotificationsToRead(data: number): Promise<string> {
    return Http.post('/m.api/admin/updateNotificationsToRead', {
      notificationsId: data,
    });
  }

  /**
   * 列表
   * @returns
   */
  @apiPermission(['sys:admin:list'], {})
  static list(data: any): Promise<any> {
    return Http.post('/m.api/admin/list', data);
  }
  /**
   * 创建用户
   * @returns
   */
  @apiPermission(['sys:admin:create'], '')
  static create(data: any): Promise<any> {
    return Http.post('/m.api/admin/create', data);
  }

  /**
   * 编辑
   * @returns
   */
  @apiPermission(['sys:admin:edit'], '')
  static edit(data: any): Promise<string> {
    return Http.post('/m.api/admin/edit', data);
  }

  /**
   * 删除用户
   * @returns
   */
  @apiPermission(['sys:admin:delete'], '')
  static delete(data: any): Promise<any> {
    return Http.post('/m.api/admin/delete', data);
  }
  /**
   * 编辑状态
   * @returns
   */
  @apiPermission(['sys:admin:editstatus'], '')
  static editStatus(data: any): Promise<any> {
    return Http.post('/m.api/admin/editStatus', data);
  }
  /**
   * 发送默认密码
   * @returns
   */
  @apiPermission(['sys:admin:sendDefaultPassword'], '')
  static sendDefaultPassword(data: any): Promise<any> {
    return Http.post('/m.api/admin/sendDefaultPassword', data);
  }
  /**
   * 重置密码
   * @returns
   */
  @apiPermission(['sys:admin:resetpassword'], '')
  static resetPassword(data: any): Promise<any> {
    return Http.post('/m.api/admin/resetPassword', data);
  }
  /**
   * 管理员列表基本信息
   * @returns
   */
  static listAdminBasic(data: any): Promise<any> {
    return Http.post('/m.api/admin/listAdminBasic', data);
  }

  /**
   * 获取管理员总数
   * @returns
   */
  static adminCount(data: any): Promise<any> {
    return Http.post('/m.api/admin/adminCount', data);
  }

  /**
   * 更新通知为已读(批量)
   * @returns
   */
  static updateNotificationsToReadBatch(data: any): Promise<string> {
    return Http.post('/m.api/admin/updateNotificationsToReadBatch', data);
  }

  /**
   * 获取未读通知数量
   * @returns
   */
  static notificationUnreadCount(): Promise<NotificationUnreadCount> {
    return Http.post('/m.api/admin/notificationUnreadCount');
  }

  /**
   * 获取未读通知数量
   * @returns
   */
  static notificationUnreadCount1(): Promise<NotificationUnreadCount> {
    return Http.post('/m.api/admin/notificationUnreadCount', null, {
      // @ts-ignore 检测到未登录，不进行任何处理
      notAuthLogin: true,
      // @ts-ignore 检测到返回错误信息，不处理
      hideErrorMsg: true,
    });
  }

  /**
   * 获取授权数据
   * @returns
   */
  @apiPermission(['sys:admin:grantfolders'], [])
  static getGrantFolders(data: { targetAdminId: number }): Promise<string[]> {
    return Http.post('/m.api/admin/getGrantFolders', data);
  }

  /**
   * 编辑基本信息
   * @returns
   */
  static editBasic(data: { avatarUrl: string }): Promise<string> {
    return Http.post('/m.api/admin/editBasic', data);
  }
}
