<template>
  <t-config-provider>
    <router-view v-slot="{ Component, route }">
      <keep-alive>
        <component v-if="route.meta?.cache" :is="Component" :key="route.fullPath" />
      </keep-alive>
      <component v-if="!route.meta?.cache" :is="Component" :key="route.fullPath" />
    </router-view>
  </t-config-provider>
</template>

<script setup lang="ts">
import api from '@/api/index';
import { useMenuStatistics } from '@/hooks/use-menu-statistics';

// import { useRoute } from 'vue-router';
// const route = useRoute();

const { init } = useMenuStatistics();

onMounted(() => {
  // 获取用户信息
  api.admin.getUserInfo1().then(() => {
    init();
  });
});
</script>

<style scoped lang="scss"></style>
