import { useMenuStatisticsStore } from '@/store/modules/menu-statistics';
import api from '@/api/index';

/**
 * 左侧菜单栏数据统计
 */
export const useMenuStatistics = () => {
  const storeMenuStatistics = useMenuStatisticsStore();

  const getStatistics = computed(() => {
    return (field: string) => {
      if (!field) {
        return '';
      }
      const all = storeMenuStatistics.getAll;
      return all[field] ? all[field] : '';
    };
  });

  /**
   * 素材组
   */
  const getApiMaterialGroup = () => {
    api.statistics.folderCountStatistics1({}).then((res) => {
      storeMenuStatistics.setOne('materialGroup', res || 0);
    });
  };

  /**
   * 图片
   */
  const getApiMaterialPicture = () => {
    api.statistics.mediaCountStatistics1({ type: 'image' }).then((res) => {
      storeMenuStatistics.setOne('materialPicture', res || 0);
    });
  };

  /**
   * 视频
   */
  const getApiMaterialVideo = () => {
    api.statistics.mediaCountStatistics1({ type: 'video' }).then((res) => {
      storeMenuStatistics.setOne('materialVideo', res || 0);
    });
  };

  /**
   * 文档
   */
  const getApiMaterialOffice = () => {
    api.statistics.mediaCountStatistics1({ type: 'document' }).then((res) => {
      storeMenuStatistics.setOne('materialOffice', res || 0);
    });
  };

  /**
   * 我的审批
   */
  const getApiApprovalMy = () => {
    const queryDTO = {
      pageNo: 1,
      pageSize: 1,
      name: '',
      tagIdList: [],
      formatList: [],
      extInfoMap: {},
    };
    api.mediaDraft
      .canApproveList1({
        queryDTO: queryDTO,
      })
      .then((res) => {
        storeMenuStatistics.setOne('approvalMy', res.count || 0);
      });
  };

  /**
   * 我的提交
   */
  const getApiApprovalDoing = () => {
    const queryDTO = {
      pageNo: 1,
      pageSize: 1,
      name: '',
      tagIdList: [],
      formatList: [],
      statusList: [],
      extInfoMap: {},
    };
    api.mediaDraft
      .draftSelfList1({
        queryDTO: queryDTO,
      })
      .then((res) => {
        storeMenuStatistics.setOne('approvalDoing', res.count || 0);
      });
  };

  /**
   * 我的任务
   */
  const getApiTaskMy = () => {
    const params = {
      pageNo: 1,
      pageSize: 1,
      statuses: [],
      createDate: '',
    };
    api.task.listMy1(params).then((res) => {
      storeMenuStatistics.setOne('taskMy', res.count || 0);
    });
  };

  /**
   * 指派任务
   */
  const getApiTaskIssue = () => {
    const params = {
      pageNo: 1,
      pageSize: 1,
      statuses: [],
      overdueDate: [],
      adminIds: [],
      departmentIds: [],
    };
    api.task.list1(params).then((res) => {
      storeMenuStatistics.setOne('taskIssue', res.count || 0);
    });
  };

  /**
   * 消息
   */
  const getApiMessage = () => {
    api.admin.notificationUnreadCount1().then((res) => {
      storeMenuStatistics.setOne('message', (res && res.count) || 0);
    });
  };

  const myAggregationStatistics = () => {
    api.statistics.myAggregationStatistics().then((res) => {
      // 素材组
      storeMenuStatistics.setOne('materialGroup', res.folderCount || 0);
      // 图片
      storeMenuStatistics.setOne('materialPicture', res.imageCount || 0);
      // 视频
      storeMenuStatistics.setOne('materialVideo', res.videoCount || 0);
      // 文档
      storeMenuStatistics.setOne('materialOffice', res.documentCount || 0);
      // 我的审批
      storeMenuStatistics.setOne('approvalMy', res.canApproveCount || 0);
      // 我的提交
      storeMenuStatistics.setOne('approvalDoing', res.myDraftCount || 0);
      // 我的任务
      storeMenuStatistics.setOne('taskMy', res.myTaskCount || 0);
      // 指派任务
      storeMenuStatistics.setOne('taskIssue', res.taskCount || 0);
    });
  }

  /**
   * 更新通知为已读
   */
  const updateApiMessage = (notificationsId: number) => {
    api.admin.updateNotificationsToRead(notificationsId).then(() => {
      getApiMessage();
    });
  };

  const init = async () => {
    // getApiMaterialGroup();
    // getApiMaterialPicture();
    // getApiMaterialVideo();
    // getApiMaterialOffice();
    // getApiApprovalMy();
    // getApiApprovalDoing();
    // getApiTaskMy();
    // getApiTaskIssue();
    myAggregationStatistics();
    getApiMessage();
  };

  return { getStatistics, init, getApiMessage, updateApiMessage };
};
